import breadcrumbComp from "../../common/breadcrumb-comp.vue";
// import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
//import recevingService from "./receive-srvice";
import FilterComp from "../../common/filter-comp.vue";
//import moment from "moment";
//import utility from "@/shared/utility";
import DisplayTextData from "@/components/common/display-text-data.vue";
import ReadOnlyDialog from "@/components/common/read-only-dialog.vue";
import { bus } from "@/main";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      receiveType: "",
      poObj: {
        UserId: this.userId,
        ProjKey: "",
        PartNum: "",
        Vendor: "",
        show_closed: "",
        PageNumber: 1,
      },
      soObj: {
        UserId: this.userId,
        ProjKey: "",
        RITM: "",
        RequestNumber: "",
        SerialNo: "",
        Waybill: "",
        show_closed: "",
        PageNumber: 1,
      },
      showDetails:true,
      displayArrayData:[
        ""
      ]
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    this.receiveType = this.$route.path.replace("/", "")?.split("/")[1];
    console.log(this.route);
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    if (this.receiveType === "SO" || this.receiveType === "PO") {
      this.mainPage = "Receiving";
      this.favouriteList = data.favouriteList;
      this.pageUrl = "receive";
      this.subPage = `${this.receiveType} Receiving`;
    }
  },
  computed: {},

  async mounted() {
    bus.$on("receiveDetails", (data) => {
      console.log(data);
    });
  },
  methods: {},
  components: {
    breadcrumbComp,
    FilterComp,
    DisplayTextData,
    ReadOnlyDialog,
  },
};
